export default {
  name: 'PartnerMenu',
  props: {
    menu: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    type: {
      type: String,
      default: 'list',
      validator: value => ['list', 'cards'].includes(value)
    }
  },
  methods: {
    imageUrl(imageUrl) {
      return require('../../assets/menus/' + imageUrl);
    },

    proccessedFlags(flags) {
      if (flags && flags.length > 0) {
        return flags.map(flag => {
          switch (flag) {
            case 'vegan':
              return {
                label: 'Vegan',
                type: 'success',
                color: '#0CB353'
              };

            case 'veg':
              return {
                label: 'Vegetarisk',
                type: 'success',
                color: '#2ECC71'
              };

            case 'lakto-ovo':
              return {
                label: 'Lakto-ovo',
                type: 'success',
                color: '#7FD4A3'
              };

            case 'hot':
              return {
                label: 'Stark',
                type: 'danger'
              };

            case 'dessert':
              return {
                label: 'Dessert',
                type: '',
                color: '#8DDFDC'
              };

            default:
              return {
                label: '',
                type: 'info'
              };
          }
        });
      } else return [];
    }

  }
};